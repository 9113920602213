import "./Sidebar.css";

export default function Menu({menuOpen, setMenuOpen, setMenuBorder}) {

  return (
    <div className={"sidebar "+(menuOpen && "active")}>
      <ul>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#home" onClick={() => setMenuBorder(false)}>Home</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#about" onClick={() => setMenuBorder(true)}>About</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#who-we-help" onClick={() => setMenuBorder(true)}>Who We Help</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="https://techtalk.org.nz/" target="_blank">Seminars</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#team" onClick={() => setMenuBorder(true)}>Our Team</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#contact" onClick={() => setMenuBorder(true)}>Contact</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a id="sign-in-link" href="https://app.platformpi.com/auth/login">Login</a>
        </li>
      </ul>
    </div>
  );
}