import React, { useEffect } from "react";
import emailjs from '@emailjs/browser';
import "./Contact.css";

const Final = ({values}) => {

  useEffect(() => {
    sendEmail(values);
  })

  const sendEmail = (e) => {
    emailjs.send('service_ezxnldj', 'template_rn4rruq', values, 'M8L4Sur0BJH2FkFet')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="form-end">
      <h1>Thank you</h1>
      <p>We have received your request and will get back to you in the next 3 working days.</p>
    </div>
  );
};

export default Final;