import { useState, useEffect } from 'react';
import './App.css';
import Menu from './Components/Menu/Menu';
import Sidebar from './Components/Sidebar/Sidebar';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import WhoWeHelp from './Components/WhoWeHelp/WhoWeHelp';
import Team from './Components/Team/Team';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';

function PlatformPi() {

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuBorder, setMenuBorder] = useState(false);
  const [aboutReveal, setAboutReveal] = useState(false);
  const [wwhReveal, setWWHReveal] = useState(false);
  const [teamReveal, setTeamReveal] = useState(false);

  useEffect(() => {

    function revealText() {
      var aElement = document.querySelector('.about-body');
      var aPosition = aElement.getBoundingClientRect();

      // checking for partial visibility
      if(aPosition.top < window.innerHeight && aPosition.bottom >= 0) {
        setAboutReveal(true);
      }


      var wElement = document.querySelector('.wwh-containers');
      var wPosition = wElement.getBoundingClientRect();

      // checking for partial visibility
      if(wPosition.top < window.innerHeight && wPosition.bottom >= 0) {
        setWWHReveal(true);
      }


      var tElement = document.querySelector('.team');
      var tPosition = tElement.getBoundingClientRect();

      // checking for partial visibility
      if(tPosition.top < window.innerHeight && tPosition.bottom >= 0) {
        setTeamReveal(true);
      }
    }

    document.addEventListener('wheel', revealText);
  })

  return (
    <div className="app">
      <Sidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} setMenuBorder={setMenuBorder} />
      <Menu menuOpen={menuOpen} 
      setMenuOpen={setMenuOpen} 
      menuBorder={menuBorder} 
      setMenuBorder={setMenuBorder}
      setAboutReveal={setAboutReveal}
      setWWHReveal={setWWHReveal}
      setTeamReveal={setTeamReveal} />

      <div className="sections">
        <Home setAboutReveal={setAboutReveal} setMenuBorder={setMenuBorder} />
        <About aboutReveal={aboutReveal} setWWHReveal={setWWHReveal} />
        <WhoWeHelp wwhReveal={wwhReveal} setTeamReveal={setTeamReveal} />
        {/* <Team teamReveal={teamReveal} /> */}

        <div className="web-bottom">
          <Contact />
          <Footer/>
        </div>

      </div>
      
      
    </div>
  );
}

export default PlatformPi;
