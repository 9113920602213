import "./WhoWeHelp.css";
import institutions from "./institutions.png"
import students from "./students.png"
import employers from "./employers.png"
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export default function WhoWeHelp({wwhReveal, setTeamReveal}) {

    return (
        <div className="who-we-help" id="who-we-help">

            <div className={"who-we-help-heading "+(wwhReveal && "active")}>
                <h1>Who We Help</h1>
            </div>

            <div className={"wwh-containers "+(wwhReveal && "active")}>
                <div className="container container1">
                    <h2>Institutions</h2>
                    <img src={institutions} alt="institutions symbol" />
                    <p>PlatformPi manages project and industrial based learning to monitor and supervise students' activities by communicating with institutions.</p>
                </div>

                <div className="container container2">
                    <h2>Students</h2>
                    <img src={students} alt="students symbol" />
                    <p>PlatformPi aids students in obtaining project and industrial experience relevant to their degree by searching and applying to a vast range of opportunities.</p>
                </div>

                <div className="container container3">
                    <h2>Employers</h2>
                    <img src={employers} alt="employers symbol" />
                    <p>PlatformPi is an efficient talent pool platform in filling critical skill shortages allowing employers to find their ideal candidate.</p>
                </div>
            </div>

            <div className="wwh-down-arrow">
                <a href="#team" onClick={() => setTeamReveal(true)}><ExpandCircleDownIcon fontSize="large" className="go-down"/></a>
            </div>

        </div>
    );
}