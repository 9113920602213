import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-col">
            <h4>PlatformPi</h4>
            <ul>
              <li><a href="#about">About Us</a></li>
              <li><a href="#who-we-help">Our Services</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/terms">Terms and Conditions</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Additional Services</h4>
            <ul>
              <li><a target="_blank" href="https://techtalk.org.nz/">Seminars</a></li>
              <li><a target="_blank" href="https://www.careers.govt.nz/job-hunting/new-to-new-zealand/tips-for-creating-a-nz-style-cv/">CV/Resume Assistance</a></li>
              <li><a target="_blank" href="https://www.resume.com/">CV/Resume Tool</a></li>
              <li><a target="_blank" href="https://www.careers.govt.nz/job-hunting/new-to-new-zealand/job-interviews-in-new-zealand/">Interview Assistance</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Follow Us</h4>
            <div className="footer-social-links">
              <ul>
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/platformpi/"><LinkedInIcon/></a>
                  <a target="_blank" href="https://twitter.com/platformpi_"><TwitterIcon/></a>
                  <a target="_blank" href="https://www.instagram.com/platformpi_"><InstagramIcon/></a>
                  <a target="_blank" href="https://www.facebook.com/platformpi.fb"><FacebookIcon/></a>
                  <a target="_blank" href="https://www.youtube.com/channel/UCZgkcO9F52fsu89cV6S1y9g"><YouTubeIcon/></a>
                  <a target="_blank" href="mailto:info@platformpi.com"><EmailIcon/></a>  
               </li>
              </ul>
            </div>
            <div className="contact-info">0800 314 800</div>
            <div className="contact-info">314-390 Khyber Pass Road</div>
            <div className="contact-info">Newmarket, Auckland 1023</div>
            <div className="copyright">PlatformPi © 2022</div>
          </div>
        </div>
      </div>
    </div>
    );
  }
