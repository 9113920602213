import React, { useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import "./Contact.css";

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, handleFormData, values }) => {
  //creating error state for validation
  const [fnameError, setFnameError] = useState(false);
  const [lnameError, setLnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    if (validator.isEmpty(values.firstName)) {
      setFnameError(true);
    } else {
      setFnameError(false);
    }
    if (validator.isEmpty(values.lastName)) {
      setLnameError(true);
    } else {
      setLnameError(false);
    }
    if (validator.isEmpty(values.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (validator.isEmpty(values.mobile)) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }
    if (
      !validator.isEmpty(values.firstName) &&
      !validator.isEmpty(values.lastName) &&
      !validator.isEmpty(values.email) &&
      !validator.isEmpty(values.mobile)
    ) {
      nextStep();
    }

  }

  return (
    <div className="step-component">
      <Card>
        <Card.Body className="card">
          <Form onSubmit={submitFormData} className="step-one">

          <span><b>Step 1 of 3</b></span>
            
            
            <Form.Group className="mb-3">
              <Form.Control
                className="contact-input"
                name="firstName"
                defaultValue={values.firstName}
                type="text"
                placeholder="First Name"
                onChange={handleFormData("firstName")}
              />
              {fnameError ? (
                <Form.Text style={{ color: "red" }}>
                  This is a required field
                </Form.Text>
              ) : (
                ""
              )}
            </Form.Group>
            
            
            <Form.Group className="mb-3">
              <Form.Control
                className="contact-input"
                name="lastName"
                defaultValue={values.lastName}
                type="text"
                placeholder="Last Name"
                onChange={handleFormData("lastName")}
              />
              {lnameError ? (
                <Form.Text style={{ color: "red" }}>
                  This is a required field
                </Form.Text>
              ) : (
                ""
              )}
            </Form.Group>
            
            
            <Form.Group className="mb-3">
              <Form.Control
                className="contact-input"
                name="email"
                defaultValue={values.email}
                type="email"
                placeholder="Email"
                onChange={handleFormData("email")}
              />
              {emailError ? (
                <Form.Text style={{ color: "red" }}>
                  This is a required field
                </Form.Text>
              ) : (
                ""
              )}
            </Form.Group>
            
            
            <Form.Group className="mb-3">
              <Form.Control
                className="contact-input"
                name="mobile"
                defaultValue={values.mobile}
                type="text"
                placeholder="Mobile"
                onChange={handleFormData("mobile")}
              />
              {mobileError ? (
                <Form.Text style={{ color: "red" }}>
                  This is a required field
                </Form.Text>
              ) : (
                ""
              )}
            </Form.Group>
            
            
            <Button variant="primary" type="submit">
              Continue
            </Button>

          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StepOne;