import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PlatformPi from './PlatformPi';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Components/TermsAndConditions/TermsAndConditions';

function App() {

  return (
    <div className="App">
     <Router>
        <Routes>

          <Route path="/privacy"
          exact
          element={<PrivacyPolicy />}
          />

          <Route path="/terms"
          exact
          element={<TermsAndConditions />}
          />

          <Route path="/"
          exact
          element={<PlatformPi />}
          />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
