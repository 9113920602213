import "./Menu.css";
import { useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu';

export default function Menu({menuOpen, setMenuOpen, menuBorder, setMenuBorder, setAboutReveal, setWWHReveal, setTeamReveal}) {

    useEffect(() => {
        function revealMenuBorder() {
          const homePage = document.querySelector(".home");
          const homeY = homePage.getBoundingClientRect().y;
    
          if (homeY < 90) {
            setMenuBorder(true);
          } else {
            setMenuBorder(false);
          }
        }
        
        window.addEventListener("wheel", revealMenuBorder);
        window.addEventListener("touchmove", revealMenuBorder);
    })

    return (
        <div className="menu">
            <div className="menu-items">
                <div className="menu-logo">
                    <a href="#home">
                        <img className="menu-logo-img" src="./logos/PNG/96-96.png" alt="PlatformPi logo"/>
                    </a>
                </div>

                <div className="menu-navigation">
                    <a href="#home" onClick={() => setMenuBorder(false)}>Home</a>
                    <a href="#about" onClick={() => {
                        setMenuBorder(true);
                        setAboutReveal(true);}}>About</a>
                    <a href="#who-we-help" onClick={() => {
                        setMenuBorder(true);
                        setWWHReveal(true);}}>Who We Help</a>
                    <a href="https://techtalk.org.nz/" target="_blank">Seminars</a>
                    {/* <a href="#team" onClick={() => {
                        setMenuBorder(true);
                        setTeamReveal(true);}}>Our Team</a> */}
                    <a href="#contact" onClick={() => setMenuBorder(true)}>Contact</a>
                    <a id="sign-in-link" href="https://app.platformpi.com/auth/login">Login</a>
                </div>

                <div className="mobile-navigation">
                    <MenuIcon onClick={()=>setMenuOpen(!menuOpen)} />
                </div>
            </div>

            <div className={"menu-border "+(menuBorder && "active")}></div>
        </div>
    );
}