import './PrivacyPolicy.css';
import Footer from '../Footer/Footer';

export default function PrivacyPolicy() {
  return (
    <>
    <div className="privacy-policy">
      
      <div className="pp-heading">
        <div className="pp-heading-left">
          <img alt="" src="./logos/PNG/96-96.png"/>
          <h1>Privacy Policy</h1>
          
        </div>
        

        <div className="pp-heading-right">
          <a href="/"><button className="get-started-button">Home</button></a>
        </div>
      </div>
      
      <div className="pp-contents">
        <p>
          This privacy notice for PlatformPi Limited (doing business as PlatformPi) ('<b>PlatformPi</b>', '<b>we</b>', '<b>us</b>', or '<b>our</b>'), describes how and why we might collect, store, use, and/or share ('<b>process</b>') your information when you use our services ('<b>Services</b>'), such as when you: 
          <br/><br/>
          <li>Visit our website at <u><a href="http://www.platformpi.com">http://www.platformpi.com</a></u> or any website of ours that links to this privacy notice </li>
          <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
        </p>
        <p><b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:info@platformpi.com"><u>info@platformpi.com.</u></a></p>
        <h3>Table of Contents</h3>
        <ol>
          <li><a href="#1">What Information Do We Collect</a>
            <ol>
              <li><a href="#1-1">Personal information provided by you</a></li>
              <li><a href="#1-2">Sensitive information</a></li>
            </ol>
          </li>
          <li><a href="#2">How Do We Process Your Information?</a></li>
          <li><a href="#3">What Legal Bases Do We Rely on to Process Your Information?</a></li>
          <li><a href="#4">When and With Whom Do We Share Your Personal Information?</a></li>
          <li><a href="#5">Is Your Information Transferred Internationally? </a></li>
          <li><a href="#6">How Long Do We Keep Your Information? </a></li>
          <li><a href="#7">What Are Your Privacy Rights? </a>
            <ol>
              <li><a href="#7-1">Withdrawing your consent</a></li>
              <li><a href="#7-2">Opting out of marketing and promotional communications</a></li>
              <li><a href="#7-3">Account information</a></li>
            </ol>
          </li>
          <li><a href="#8">Controls for Do-Not-Track Features </a></li>
          <li><a href="#9">Do California Residents Have Specific Privacy Rights?</a>
            <ol>
              <li><a href="#9-1">CCPA privacy notice</a></li>
              <li><a href="#9-2">How do we use and share your personal information?</a></li>
              <li><a href="#9-3">Will your information be shared with anyone else?</a></li>
              <li><a href="#9-4">Your rights with respect to your personal data</a>
                <ol>
                  <li><a href="9-4-1">Right to request deletion of the data — Request to delete </a></li>
                  <li><a href="9-4-2">Right to be informed — Request to know </a></li>
                  <li><a href="9-4-3">Right to non-discrimination for the exercise of a consumer’s privacy rights </a></li>
                  <li><a href="9-4-4">Verification process </a></li>
                  <li><a href="9-4-5">Other privacy rights </a></li>
                </ol>
              </li>
            </ol>
          </li>
          <li><a href="10">Do We Make Updates to This Notice? </a></li>
          <li><a href="11">How Can You Review, Update, or Delete the Data We Collect from You? </a></li>
          <li><a href="12">How Can You Contact Us About This Notice? </a></li>
        </ol>
      </div>

      <h3>Summary of Key Points</h3>
      <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. </p>
      <p><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with PlatformPi and the Services, the choices you make, and the products and features you use. Click <a href="#1"><u>here</u></a> to learn more. </p>
      <p><b>Do we process any sensitive personal information?</b> We may process sensitive personal information, when necessary, with your consent or as otherwise permitted by applicable law. Click <a href="#1-2"><u>here</u></a> to learn more. </p>
      <p><b>Do we receive any information from third parties?</b> We do not receive any information from third parties. Except for students’ enrolment information from their institutions. </p>
      <p><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click <a href="#2"><u>here</u></a> to learn more. </p>
      <p><b>In what situations and with which types of parties do we share personal information?</b> We may share information in specific situations and with specific categories of third parties. Click <a href="#4"><u>here</u></a> to learn more. </p>
      <p><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click <a href="#7"><u>here</u></a> to learn more. </p>
      <p><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>

      <h3 id="1">1. What Information Do We Collect?</h3>
      <p>Personal information you disclose to us</p>
      <p><b>In Short:</b> We collect personal information that you provide to us.</p>
      <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
      
      <h4 id="1-1">1.1. Personal information provided by you</h4>
      <p>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use.</p>
      
      <h4 id="1-2">1.2. Sensitive Information</h4>
      <p>When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information: </p>
      <ul>
        <li>Information revealing race or ethnic origin</li>
        <li>Information revealing visa status </li>
        <li>Student data</li>
        <li>Payment Data</li>
      </ul>
      <p>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by TBC. You may find their privacy notice link(s) here: TBC. (We do not collect any payment data at this stage. We will update this section before collecting any payment data).</p>
      <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

      <h3 id="2">2. How Do We Process Your Information?</h3>
      <p><b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
      <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
      <ul>
        <li>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
        <li>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
        <li>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
        <li>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
        <li>To fulfil and manage your orders. We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.</li>
        <li>To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</li>
        <li>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
        <li>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time.</li>
        <li>To deliver targeted advertising to you. We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.</li>
        <li>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
        <li>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
        <li>To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
        <li>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
      </ul>

      <h3 id="3">3. What Legal Bases Do We Rely on to Process Your Information?</h3>
      <p><b>In Short:</b> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</p>
      <p><b>If you are located in the EU or UK, this section applies to you.</b></p>
      <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
      <ul>
        <li><b>Consent.</b> We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click <a href="#7-1"><u>here</u></a> to learn more.</li>
        <li><b>Performance of a Contract.</b> We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
        <li><b>Legitimate Interests.</b> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
          <ul>
            <li>Send users information about special offers and discounts on our products and services</li>
            <li>Develop and display personalised and relevant advertising content for our users</li>
            <li>Analyse how our services are used so we can improve them to engage and retain users</li>
            <li>Support our marketing activities</li>
            <li>Diagnose problems and/or prevent fraudulent activities</li>
            <li>Understand how our users use our products and services so we can improve user experience</li>
          </ul>
        </li>
        <li><b>Legal Obligations.</b> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
        <li><b>Vital Interests.</b> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
      </ul>
      <p><b>If you are located in Canada, this section applies to you.</b></p>
      <p>We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time. Click <a href="#7-1"><u>here</u></a> to learn more.</p>
      <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
      <ul>
        <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way </li>
        <li>For investigations and fraud detection and prevention </li>
        <li>For business transactions provided certain conditions are met </li>
        <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim </li>
        <li>For identifying injured, ill, or deceased persons and communicating with next of kin </li>
        <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse </li>
        <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province </li>
        <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records </li>
        <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced </li>
        <li>If the collection is solely for journalistic, artistic, or literary purposes </li>
        <li>If the information is publicly available and is specified by the regulations </li>
      </ul>

      <h3 id="4">4. When and With Whom Do We Share Your Personal Information?</h3>
      <p><b>In Short: </b>We may share information in specific situations described in this section and/or with the following categories of third parties. </p>
      <p><b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are as follows: </p>
      <ul>
        <li>Order Fulfilment Service Providers </li>
        <li>Payment Processors </li>
        <li>Cloud Computing Services </li>
        <li>Data Analytics Services </li>
        <li>Data Storage Service Providers </li>
        <li>User Account Registration and Authentication Services </li>
        <li>Website Hosting Service Providers </li>
        <li>Testing Tools </li>
        <li>Sales and Marketing Tools </li>
        <li>Product Engineering and Design Tools </li>
        <li>Performance Monitoring Tools </li>
        <li>Employers </li>
      </ul>
      <p>We also may need to share your personal information in the following situations: </p>
      <ul>
        <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. </li>
        <li><b>Affiliates.</b> We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us. </li>
        <li><b>Business Partners.</b> We may share your information with our business partners to offer you certain products, services, or promotions. </li>
        <li><b>Other Users.</b> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile. </li>
      </ul>

      <h3 id="5">5. Is Your Information Transferred Internationally?</h3>
      <p><b>In Short:</b> We may transfer, store, and process your information in countries other than your own. </p>
      <p>Our servers are located in New Zealand and Australia. If you are accessing our Services from outside New Zealand and Australia, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see '‎Section 4' above), in All around the world, and other countries. </p>
      <p>If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law. </p>

      <h3 id="6">6. How Long Do We Keep Your Information?</h3>
      <p><b>In Short:</b> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law. </p>
      <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than six (6) months past the termination of the user's account. </p>
      <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. </p>

      <h3 id="7">7. What Are Your Privacy Rights?</h3>
      <p><b>In Short:</b> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time. </p>
      <p>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <a href="#11"><u>'How Can You Contact Us About This Notice?'</u></a> below. </p>
      <p>We will consider and act upon any request in accordance with applicable data protection laws. </p>
      <p> If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: </p>
      <p><a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"><u>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</u></a></p>
      <p>If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html"><u>https://www.edoeb.admin.ch/edoeb/en/home.html.</u></a> </p>

      <h4 id="7-1">7.1. Withdrawing your consent</h4>
      <p>If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <a href="#7-1"><u>'How Can You Contact Us About This Notice?'</u></a> below or updating your preferences.</p>
      <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. </p>

      <h4 id="7-2">7.2. Opting out of marketing and promotional communications</h4>
      <p>You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying 'STOP' or 'UNSUBSCRIBE' to the SMS messages that we send, or by contacting us using the details provided in the section 'how Can You Contact Us About This Notice?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. </p>

      <h4 id="7-3">7.3. Account information</h4>
      <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
      <ul>
        <li>Log in to your account settings and update your user account. </li>
        <li>Contact us using the contact information provided. </li>
      </ul>
      <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements. </p>
      <p>If you have questions or comments about your privacy rights, you may email us at: <a href="mailto:support@platformpi.com"><u>support@platformpi.com</u></a></p>

      <h3 id="8.">8. Controls for Do-Not-Track Features </h3>
      <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. </p>

      <h3 id="9.">9. Do California Residents Have Specific Privacy Rights? </h3>
      <p><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information. </p>
      <p>California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below. </p>
      <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.). </p>

      <h4 id="9-1">9.1. CCPA privacy notice</h4>
      <p>The California Code of Regulations defines a 'resident' as: </p>
      <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose and </p>
      <p>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose </p>
      <p>All other individuals are defined as 'non-residents'. </p>
      <p>If this definition of 'resident' applies to you, we must adhere to certain rights and obligations regarding your personal information. </p>

      <h4 id="9-2">9.2. How do we use and share your personal information? </h4>
      <p>More information about our data collection and sharing practices can be found in this privacy notice. </p>
      <p>You may contact us by email at <a href="mailto:support@platformpi.com"><u>support@platformpi.com</u></a>, or by referring to the contact details at the bottom of this document. </p>
      <p>If you are using an authorised agent to exercise your right to opt-out we may deny a request if the authorised agent does not submit proof that they have been validly authorised to act on your behalf. </p>

      <h4 id="9-3">9.3. Will your information be shared with anyone else? </h4>
      <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf. </p>
      <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information. </p>
      <p>The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under <a href="#4"><u>'When and With Whom Do We Share Your Personal Information?'</u></a>. </p>

      <h4 id="9-4">9.4. Your rights with respect to your personal data </h4>

      <h5 id="9-4-1">9.4.1. Right to request deletion of the data — Request to delete </h5>
      <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities. </p>

      <h5 id="9-4-2">9.4.2. Right to be informed — Request to know </h5>
      <p>Depending on the circumstances, you have a right to know: </p>
      <ul>
        <li>whether we collect and use your personal information; </li>
        <li>the categories of personal information that we collect; </li>
        <li>the purposes for which the collected personal information is used; </li>
        <li>whether we sell your personal information to third parties; </li>
        <li>the categories of personal information that we sold or disclosed for a business purpose; </li>
        <li>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and </li>
        <li>the business or commercial purpose for collecting or selling personal information. </li>
      </ul>
      <p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request. </p>

      <h5 id="9-4-3">9.4.3. Right to non-discrimination for the exercise of a consumer’s privacy rights </h5>
      <p>We will not discriminate against you if you exercise your privacy rights. </p>

      <h5 id="9-4-4">9.4.4. Verification process </h5>
      <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate. </p>
      <p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you. </p>

      <h5 id="9-4-5">9.4.5. Other privacy rights</h5>
      <ul>
        <li>You may object to the processing of your personal information. </li>
        <li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information. </li>
        <li>You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with the CCPA. </li>
        <li>You may request to opt-out from future selling of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission. </li>
      </ul>
      <p>To exercise these rights, you can contact us by email at <a href="mailto:support@platformpi.com"><u>support@platformpi.com</u></a>, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you. </p>
  
      <h3 id="10">10. Do We Make Updates to This Notice? </h3>
      <p><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws. </p>
      <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information. </p>

      <h3 id="11">11. How Can You Review, Update, or Delete the Data We Collect from You? </h3>
      <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit your personal account/organisation account at our website. </p>

      <h3 id="12">12. How Can You Contact Us About This Notice? </h3>
      <p>If you have questions or comments about this notice, you may email us at <a href="mailto:support@platformpi.com"><u>support@platformpi.com</u></a>.</p>
      <br/>
      <h5>Last updated at June 22, 2022</h5>
    </div>
    <Footer/>
    </>
  );
}