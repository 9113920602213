import "./About.css";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export default function About({aboutReveal, setWWHReveal}) {

  return (
    <div className="about" id="about">
        <div className={"about-heading "+(aboutReveal && "active")}>
            <h1>About</h1>
            <h1>PlatformPi</h1>
        </div>

        <div className={"about-body "+(aboutReveal && "active")}>
            <h2>PlatformPi aims to Simplify and Enhance Education-Industry Collaboration and Engagement.</h2>
            <p>We are a collaborative education system that bridges the gap between students, tertiary providers, and the technology industry. PlatformPi provides a medium like no other where students can find opportunities and learn lifelong skills through two approaches - Project Based Learning and Internship Based Learning. We help connect a diverse range of employers to students from our large talent pool so that both can benefit in this rapidly growing industry.</p>
            <a href="#contact"><button className="get-started-button">Sign up to PlatformPi</button></a>
        </div>

        <div className="about-down-arrow">
            <a href="#who-we-help" onClick={() => setWWHReveal(true)}><ExpandCircleDownIcon fontSize="large" className="go-down"/></a>
         </div>
    </div>
  );
}