import React, { useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import "./Contact.css";

// creating functional component ans getting props from app.js and destucturing them
const StepThree = ({ nextStep, handleFormData, values, prevStep }) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (
      validator.isEmpty(values.message)
    ) {
      setError(true);
    } else {
      nextStep();
    }
  };

  return (
    <div className="step-component">
      <Card>
        <Card.Body>
          <Form onSubmit={submitFormData} className="step-three">

          <span><b>Step 3 of 3</b></span>


            <div className="userNeedContainer">
                <label className="userPurpose-heading">Select your need</label>
                <div className="userNeed">
                    <label className="checkbox-input"><input type="checkbox" value="Yes"onChange={handleFormData("generalInquiry")}/>General inquiry</label>
                    <label className="checkbox-input"><input type="checkbox" value="Yes" onChange={handleFormData("bookDemo")}/>I want to book a demo</label>
                    <label className="checkbox-input"><input type="checkbox" value="Yes" onChange={handleFormData("needSupport")}/>I am needing support</label>
                </div>
            </div>
            

            <Form.Group className="mb-3">
            <textarea placeholder="How can we help?" onChange={handleFormData("message")} className="contact-textarea"></textarea>
              {error ? (
                <Form.Text style={{ color: "red" }}>
                  This is a required field
                </Form.Text>
              ) : (
                ""
              )}
            </Form.Group>


            <div className="nextPrevButtons">
                <Button variant="primary" onClick={prevStep}>
                    Previous
                </Button>

                <Button variant="primary" type="submit">
                Submit
                </Button>
            </div>

          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StepThree;