import { useState } from "react";
import "./Contact.css";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import Final from "./Final";

export default function Contact() {

  const [step, setstep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    country: "New Zealand",
    user: "Student",
    organisation: "",
    jobTitle: "",
    generalInquiry: "",
    bookDemo: "",
    needSupport: "",
    message: ""
  })

  const nextStep = () => {
    setstep(step + 1);
  };

  const prevStep = () => {
    setstep(step - 1);
  };

  const handleInputData = input => e => {
    const {value} = e.target;

    setFormData(prevState => ({
      ...prevState,
      [input]: value
  }));
  }

  function contactForm() {
    switch (step) {

      case 1:
        return (
          <StepOne nextStep={nextStep} handleFormData={handleInputData} values={formData} />
        );
  
      case 2:
        return (
          <StepTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
        );
  
      case 3:
        return (
          <StepThree nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
        );
  
      case 4:
        return (
          <Final values={formData}/>
        );

      default:
        <></>
    }
  }


  return (
    <div className="contact" id="contact">
      <div className="leftContact">
        <h1>Get Started</h1>
        <h1>with PlatformPi</h1>
      </div>

      <div className="rightContact">
        {contactForm()}
      </div>
    </div>
  );
}