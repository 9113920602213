import './Home.css';
import RisingParticles from './RisingParticles';
import React from 'react';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";

export default function Home({setAboutReveal, setMenuBorder}) {

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
    }, []);

  const particlesLoaded = useCallback(async (container) => {
      await console.log(container);
  }, []);


  return (
    <div className="home" id="home">
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fullScreen: { enable: false },
                background: {
                    color: {
                        value: "#000000",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 2,
                        },
                        repulse: {
                            distance: 50,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#8449db",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.4,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 0.8,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 100,
                    },
                    opacity: {
                        value: 0.3,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true
            }}
        />     
        <div className="home-two-container">
          {/*<div className="home-opacity-screen"/>*/}
          <div className="home-cycle-wrapper">
          <img className="home-logo" src="./logos/PNG/512-512.png" alt="Our company"/>
          <div className="home-title">
            <h1>Platform<span id="home-P">P</span><span id="home-I">i</span></h1>
          </div>
            <div className="home-cycle-text-container">
              <div className="home-main-text-top">
                BRIDGING THE GAP BETWEEN
              </div>
              <div className="home-cycle-main-text-wrapper">
                <div className="home-cycle-main-text">
                  <span className="home-animate-students">STUDENTS<br/></span>
                  <span className="home-animate-employers">EMPLOYERS<br/></span>
                  <span className="home-animate-industries">INSTITUTIONS<br/></span>
                  <span className="home-animate-people">PEOPLE<br/></span>
                </div>
              </div>
              <div className="home-main-text-bot">
                AND OPPORTUNITIES
              </div>
            </div>
          </div>
          <div className="home-down-arrow">
                <a href="#about" onClick={() => {setAboutReveal(true); setMenuBorder(true);}}><ExpandCircleDownIcon fontSize="large" className="go-down"/></a>
          </div>
          
        </div>
    </div>
  );
}